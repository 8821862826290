@media (max-width: 1000px) {
  .header__title {
    max-width: 400px;
    font-size: 18px;
  }

  .header__btn {
    padding: 8px 26px;
    font-size: 18px;
  }

  .menu {
    width: 35%;
  }

  .menu__btn {
    font-size: 14px;
  }
}

@media (max-width: 800px) {
  .header__title {
    max-width: 350px;
    font-size: 15px;
  }

  .header__btn {
    padding: 4px 20px;
    font-size: 15px;
  }

  .menu__btn {
    font-size: 12px;
  }
}
